const { http, genMeta, harbourHttp } = require('./_http')


/**
 * @desc 查询司机热线头部驳空信息
 */
 exports.shortBargeDriverTask = payload => {
  return http.get("/duckbill-tts/performance/short-barge/driver-task", {
    params: payload,
    parseResp: true
  })
}
/**
 * @desc 查询司机热线列表信息
 * @param {*} 司机热线聊天信息 
 */
exports.queryDriverMessageList = payload => {
  return http.post("/duckbill-workmsg/mng/driver-posting/list", payload)
}

/**
 * @desc 查询司机热线聊天滚动信息
 * @param {{  }} 司机热线聊天信息 
 */
exports.fetchDriverChatList = payload => {
  return http.post("/duckbill-workmsg/mng/driver-posting/head-icon/chat/list", payload)
}

/**
 * @desc 查询统计面板数据
 * @param {} 司机热线聊天信息 
 */
exports.fetchDriverPostingStatistics = payload => {
  return http.post("/duckbill-workmsg/mng/driver-posting/dispatch-group/statistics", payload)
}

/**
 * @desc 获取是否需要绑定订单条
 * @param {{ containerId: number, dispatcherGroup: string }} payload 
 */
exports.queryIsNeedBindOrder = payload => {
  return http.post("/duckbill-workmsg/mng/driver-posting/need-bind-order", payload)
}

/**
 * @desc 回复司机消息
 * @param {{ }} payload 
 */
exports.replyDriverMessage = payload => {
  return http.post("/duckbill-workmsg/mng/driver-posting/head-icon/reply", payload)
}

/**
 * @desc 移交消息给客服
 * @param {{customerService: string, driverPostingIds: [number]}} payload 
 */
exports.turnoverToCustomerService = payload => {
  return http.post("/duckbill-workmsg/mng/driver-posting/turn-over/customer-service", payload)
}

/**
 * @desc 移交给受理组
 * @param {{ dispatcherGroup: string, driverPostingIds: [number] }} payload 
 */
exports.turnoverToGroup = payload => {
  return http.post("/duckbill-workmsg/mng/driver-posting/turn-over-to/dispatch-group", payload)
}

/**
 * @deprecated
 * @desc 查询所有受理组
 * @param {{showType: "DRIVER_POSTING" | "INTERNAL_POSTING"}} payload 
 */
exports.fetchDeepGroup = payload => {
  return http.post("/duckbill-workmsg/mng/dispatch-group/all", payload)
}

/**
 * @desc 查询顶级受理组
 * @param {{showType: "DRIVER_POSTING" | "INTERNAL_POSTING"}} payload 
 */
exports.fetchTopLevelGroup = payload => {
  return http.post("/duckbill-workmsg/mng/dispatch-group/top", payload)
}
/**
 * @desc 获取设置面板数据
 * @param {{showType: "DRIVER_POSTING" | "INTERNAL_POSTING"}} payload 
 */
exports.fetchDispatchGroupSettingPanel = (payload = {}) => {
  return http.post("/duckbill-workmsg/mng/dispatch-group/list-all", payload)
}

/**
 * @desc 新增或者修改受理组
 * @param {{ children: [string], dispatchGroupId: number, dispatcherGroupName: string, parentId: string}} payload 
 */
exports.addOrUpdateGroup = payload => {
  return http.post("/duckbill-workmsg/mng/dispatch-group/add-or-update", payload)
}

/**
 * @desc 获取排班
 * @param {{ date: string }} payload 
 */
exports.fetchSchedule = payload => {
  return http.post("/duckbill-workmsg/mng/transport-mgt-schedule/list", payload)
}

/**
 * @desc 更新或者设置排班
 * @param {{ dateFrom: string, dateTo: string, dispatcherGroups: [string], transportMgtScheduleId: number }} payload 
 */
exports.setSchedule = payload => {
  return http.post("/duckbill-workmsg/mng/transport-mgt-schedule/add-or-update", payload)
}


/**
 * @desc 根据信息 id 获取相关回复信息
 * @param {{ driverPostingId: number }} payload 
 */
exports.fetchRelatedMessageList = payload => {
  return http.post("/duckbill-workmsg/mng/driver-posting/query-children-driver-postings", payload)
}

/**
 * @param {{showType: "DRIVER_POSTING" | "INTERNAL_POSTING"}} payload 
 */
exports.fetchDispatchGroupSelectOptions = payload => {
  return http.get("/duckbill-workmsg/mng/dispatch-group/dispatch-group-select", payload)
}

/**
 * @desc 根据使用场景查询受理组
 * @param {{useCase: "SEND_MESSAGE" | "TURN_OVER_TO"}} payload 
 */
exports.fetchDispatchGroupByCase = payload => {
  return http.post("/duckbill-workmsg/mng/dispatch-group/query-dispatch-group-by-use-case", payload)
}

/**
 * @desc 根据层次查询司机服务受理组
 * @param {{ level: number }} payload 
 */
exports.fetchDriverServiceGroupByLevel = payload => {
  return http.post("/duckbill-workmsg/mng/dispatch-group/query-driver-service-group-by-level", {
    "showType": "DRIVER_POSTING",
    ...payload
  })
}

/**
 * @desc 根据层次查询司机服务受理组 新
 * @param 无
 */
 exports.queryAllBaseDispatchGroups = payload => {
  return http.post("/duckbill-workmsg/mng/base-dispatch-group/query-all-base-dispatch-groups", {})
}

/**
 * @desc 费用项设置查询司机服务受理组 新
 * @param 无
 */
 exports.baseGroupSelectRemoveDispatch = payload => {
  return http.get("/duckbill-workmsg/mng/base-dispatch-group/base-group-select-remove-dispatch", {})
}

/**
 * @desc 司机热线群发消息
 * @param {{ "content": "string", "fileUrl": "string", "receiverFileStream": {}, "sendFileContentType": "MOBILE_PHONE" }} payload 
 */
exports.fetchGroupSendDriverPosting = payload => {
  return http.post("/duckbill-workmsg/mng/stream/group-send-driver-posting", payload)
}

/**
 * @desc 司机热线列表
 * @param {{"createdBy": "string", "endTime": "string", "msg": "string", "startTime": "string"}} payload 
 */
exports.fetchGroupSendMsgList = payload => {
  return http.post("/duckbill-workmsg/mng/group-send-msg/list", payload)
}

/**
 * @desc 操作人
 * @param {{"name": "string"}} payload
 */
exports.fetchQueryUsersByUserIds = payload => {
  return harbourHttp.post("/work-msg/user/queryUsersByUserIds", payload, {
    parseResp: true
  })
}





/** @harbour 接口 */

/**
 * @desc 查看用户用户名
 * @param {{urseId: [number]}} payload 
 */
exports.fetchUsersByUserIds = payload => {
  return harbourHttp.post("/user/queryUsersByUserIds", payload)
}

/**
 * @desc 根据消息数据获取司机和接单人信息
 * @param {{driverMessageContentDtoList: [object]}} payload 
 */
exports.fetchTakerAndDriverInfo = payload => {
  return harbourHttp.post("/driver/getTakerAndDriverInfo", payload, {
    parseResp: true
  })
}

/**
 * @desc 根据箱 id 获取做箱时间
 * @param {{containerId?: number, taskId?: number}} payload 
 */
exports.fetchPlanArrivalTimeByContainerIdOrTakerId = payload => {
  return harbourHttp.post("/work-msg/order/queryPlanArrivalDateByContainerIdOrTakerId", payload, {
    parseResp: true
  })
}

/**
 * @desc 根据箱号获取接单人信息, 用于发消息
 * @param {{containerId: number}} payload 
 */
exports.fetchTakerByContainerId = payload => {
  return harbourHttp.get("/order_container/queryTakerIdsByContainerId", {
    params: payload,
    parseResp: true
  })
}

/**
 * @desc 根据taskId 或者箱子id 查询该单客服信息
 * @param {{containerId: number, taskId: number}} payload 
 */
exports.fetchCustomerServiceByContainerIdOrTaskId = payload => {
  return harbourHttp.get("/order_container/queryWorkInfoByContainerIdOrTaskId", {
    params: payload,
    parseResp: true
  })
}

/**
 * @desc 内部协同面板统计
 * @param {{sendCreationTimeFrom: string, sendCreationTimeTo: string}} payload 
 */
exports.fetchInternalPostingStatistics= payload => {
  return harbourHttp.get("/messenger/internal/posting/statistics", {
    params: payload,
    parseResp: true
  })
}

/**
 * @desc 获取所有港口对应的受理组策略
 * @param {{}} payload 
 */
 exports.queryAllPortStrategy= payload => {
  return harbourHttp.get("/posting-dispatch-group/strategy/query-all-port-strategy", {
    payload: payload,
    parseResp: true
  })
}

/**
 * @desc 司机热线和内部协同列表 查询处理部门、处理组、线路归属下拉框
 * @param {{showType: string}} payload 
 * @param showType: DRIVER_POSTING 司机热线 | INTERNAL_POSTING 内部协同
 */
 exports.dispatchGroupSelect = payload => {
  return http.get("/duckbill-workmsg/mng/dispatch-group/dispatch-group-select", {params: payload})
}

/**
 * @desc 查询路线受理组列表
 */
 exports.queryRouteGroupList = payload => {
  return http.get("/duckbill-workmsg/mng/dispatch-group/query-route-group-list", {params: payload})
}

/**
 * @desc  获取客服处理组
 */
 exports.customerGroupList = payload => {
  return harbourHttp.post("/customer-binding/get/group/list", payload)
}


